<template>
  <div class="container-fluid py-4  course-register">
    <div class="row">
      <div class="col-12">
      <div class="card my-4">
                  <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div
                      class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                    >
                      <h6 class="text-white text-capitalize ps-3">
                        الدورات المسجل فيها حاليا : <span class="header-span">{{ student.full_name }}</span> - الفرقة :<span class="header-span">{{student.grade_name}} </span>
                      </h6>
                    </div>
                  </div>
                  <div class="card-body px-0 pb-2">
                    <div class="table-responsive p-0">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th
                              class="text-uppercase font-weight-bolder "
                            >
                              اسم الدورة
                            </th>
                            <th
                              class="text-uppercase  font-weight-bolder "
                            >
                              المادة
                            </th>
                            <th
                              class="text-uppercase  font-weight-bolder "
                            >
                              محاضرات<br/>اون لاين
                            </th>
                            <th
                              class="text-uppercase  text-center font-weight-bolder "
                            >
                              الخصومات
                            </th>
                            <th
                              class="text-uppercase  text-center font-weight-bolder "
                            >
                              التاريخ
                            </th>
                            <th
                              class="text-uppercase  font-weight-bolder "
                            >
                              التحويل
                            </th>
                            <th
                              class="text-uppercase  font-weight-bolder "
                            >
                              الخصم
                            </th>
                            <th
                              class="text-uppercase  font-weight-bolder "
                            >
                              التسجيل
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="course in this.registeredCourses"
                            :key="course.id"
                          >
                            <td>
                              <p class="text-s mb-0">{{ course.number + '-' + course.name }}</p>
                            </td>
                            <td>
                              <p class="text-s mb-0">{{ course.subject.name }}</p>
                            </td>
                            <td v-if="course.type" class="text-center">
                              <span class="badge badge-sm bg-gradient-success"
                                ><i class="fas fa-check"></i
                              ></span>
                            </td>
                            <td v-else class="text-center">
                              <span class="badge badge-sm bg-gradient-danger">X</span>
                            </td>
                            <td>
                              <p class="text-s mb-0" v-for="discount in course.discounts" :key="discount.id">
                                {{ discount.discount_type.name }}
                              </p>
                            </td>
                            <td>
                              <p class="text-s mb-0">{{ course.pivot.created_at }}</p>
                            </td>
                            <td>
                              <span
                                @click="transformCourse(course)"
                                class="badge badge-sm bg-gradient-primary pointer mx-1"
                                >التحويل لدورة</span
                              >
                              </td>
                            <td>
                              <span
                                @click="addDiscount(course)"
                                class="badge badge-sm bg-gradient-info pointer mx-1"
                                >اضافة خصم</span
                              >
                              <span
                                v-if="course.discounts"
                                @click="removeDiscount(course)"
                                class="badge badge-sm bg-gradient-warning pointer mx-1"
                                >تعديل الخصومات</span
                              >
                              </td>
                              <td>
                              <span
                                id="unroll"
                                @click="Unroll(course.id)"
                                class="badge badge-sm bg-gradient-danger pointer mx-1"
                                >إلغاء التسجيل</span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">
                التسجيل في الدورة
              </h6>
            </div>
          </div>
          <div class="card-body px-4 pb-2">
            <!-- current registration -->
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="mb-3 col-lg-3">
                    <label>
                      المواد
                    </label>
                    <multiselect
                      class="required form-control"
                      v-model="selectedSubject"
                      :options="subjects"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder="اختر المادة"
                      label="name"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="text-center col-lg-2">
                      <vmd-button
                      style=" width: 100%;"
                        class="my-4 mb-2"
                        variant="gradient"
                        color="info"
                        @click='filterDate'
                        > فلتر
                      </vmd-button>
                    </div>
                </div>
                <div class="row tabel-row all-student">
                  <vdtnet-table
                    class="table-striped table table-borderless"
                    :jquery="jq"
                    ref="table"
                    :fields="fields"
                    :opts="options"
                    @register="doAlertRegister"
                  >
                  </vdtnet-table>
                </div>
              </div>
            </div>
          </div>
       <!-- start student course discount modal -->
        <vue-final-modal
          :clickToClose="true"
          v-model="showModal"
          name="example"
          classes="modal-container"
          content-class="modal-content"
        >
          <div class="row">
            <div class="col-12">
              <div class="card my-4">
                <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div
                    class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                  >
                    <h6 class="text-white text-capitalize ps-3">إضافة خصم</h6>
                  </div>
                </div>
                <div class="card-body px-4 pb-2">
                 <form @submit.prevent="createDiscount" role="form" class="text-start mt-3">
                    <div class="row">
                      <div class="mb-3 col-lg-6">
                        <label>
                          نوع الخصم
                          <span color="danger" class="red">*</span></label
                        >
                          <multiselect
                            class='required form-control'
                            id='grade'
                            v-model="selectedDiscount.type"
                            :options='discountTypes'
                            :searchable='true'
                            :close-on-select='true'
                            placeholder=" اختر نوع الخصم"
                            label='name'
                            track-by='id'
                            required='required'
                            :internal-search="true"
                            >
                            <template v-slot:noOptions>
                              <span> فارغة </span>
                            </template>
                            <template v-slot:noResult>
                              <span>لا يوجد نتيجة </span>
                            </template>
                          </multiselect>
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-3 col-lg-6">
                        <label>
                          رقم الدورة
                          <span color="danger" class="red">*</span></label
                        >
                        <div class="input-group input-group-outline null">
                          <vmd-input
                            id="receipt-student"
                            type="text"
                            disabled
                            v-model="selectedDiscount.course.number"
                          />
                        </div>
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label>
                          اسم الدورة
                          <span color="danger" class="red">*</span>
                        </label>
                        <div class="input-group input-group-outline null">
                          <vmd-input
                            id="receipt-student"
                            type="text"
                            disabled
                            v-model="selectedDiscount.course.name"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="row discount-amount"
                      v-if="selectedDiscount.type && selectedDiscount.type.name_en === 'course_discount'"
                    >
                      <div class="mb-3 col-lg-6">
                        <label>
                          القيمة <span color="danger" class="red">*</span></label
                        >
                        <vmd-input
                          id="discount-amount"
                          type="number"
                          placeholder='القيمة'
                          v-model="selectedDiscount.amount"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="text-center col-lg-6">
                        <vmd-button
                          class="my-4 mb-2"
                          variant="gradient"
                          color="info"
                          @click="showModal = false"
                        >
                          حفظ</vmd-button
                        >
                      </div>
                      <div class="text-center col-lg-6">
                        <vmd-button
                          type="reset"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="danger"
                          @click="showModal = false"
                        >
                          إلغاء
                        </vmd-button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </vue-final-modal>
        <!-- end student course discount modal-->
       <!-- start student course discount edit modal -->
        <vue-final-modal
          :clickToClose="true"
          v-model="showDiscountModal"
          name="example"
          classes="modal-container"
          content-class="modal-content"
        >
          <div class="row">
            <div class="col-12">
              <div class="card my-4">
                <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div
                    class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                  >
                    <h6 class="text-white text-capitalize ps-3">تعديل الخصومات</h6>
                  </div>
                </div>
                <div class="card-body px-4 pb-2">
                  <div class="row">
                    <div class="mb-3 col-lg-6">
                      <label>
                        رقم الدورة
                        <span color="danger" class="red">*</span></label
                      >
                      <div class="input-group input-group-outline null">
                        <vmd-input
                          id="receipt-student"
                          type="text"
                          disabled
                          v-model="selectedDiscount.course.number"
                        />
                      </div>
                    </div>
                    <div class="mb-3 col-lg-6">
                      <label>
                        اسم الدورة
                        <span color="danger" class="red">*</span>
                      </label>
                      <div class="input-group input-group-outline null">
                        <vmd-input
                          id="receipt-student"
                          type="text"
                          disabled
                          v-model="selectedDiscount.course.name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th>الخصم</th>
                          <th>القيمة</th>
                          <th>تعديل</th>
                          <th>حذف</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="discount in selectedDiscount.course.discounts" :key="discount.id">
                          <td>{{discount.discount_type.name}}</td>
                          <td>
                            <vmd-input
                              id="receipt-student"
                              type="text"
                              v-if="(discount.discount_type.name_en === 'course_discount')"
                              v-model="discount.amount"
                            />
                          </td>
                          <td>
                            <vmd-button
                              v-if="(discount.discount_type.name_en === 'course_discount')"
                              class="my-4 mb-2"
                              variant="gradient"
                              color="info"
                              @click="editDiscount(discount, selectedDiscount)"
                            >
                              تعديل</vmd-button
                            >
                          </td>
                          <td>
                            <vmd-button
                              class="my-4 mb-2"
                              variant="gradient"
                              color="danger"
                              @click="deleteDiscount(discount, selectedDiscount)"
                            >
                              حذف</vmd-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-final-modal>
        <!-- end student course discount modal-->
        <!-- start student transform course modal-->
        <vue-final-modal
          :clickToClose="true"
          v-model="showTransformModal"
          name="example"
          classes="modal-container"
          content-class="modal-content"
        >
          <TransformCourse @hide-transform-modal='completeTransform' :course="fromCourse" :student="student" :courses-list="allCourses"></TransformCourse>
        </vue-final-modal>
        <!-- end student transform course modal-->
    </div>
  </div>
</template>

<script>
import TransformCourse from '@/components/TransformCourse.vue'
import VmdInput from '@/components/VmdInput.vue'
import VdtnetTable from 'vue-datatables-net'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'
import $ from 'jquery'
import { VueFinalModal } from 'vue-final-modal'
let selectedSubject = ''
$('.discount-amount').css('display', 'none')
export default {
  name: 'CourseRegister',
  components: {
    VueFinalModal,
    VmdInput,
    VdtnetTable,
    VmdButton,
    TransformCourse
  },
  data () {
    return {
      subjects: [],
      selectedSubject: '',
      grades: [],
      selectedGrade: '',
      student: {},
      offers: [],
      allCourses: [],
      fromCourse: {},
      registeredCourses: [],
      showModal: false,
      showDiscountModal: false,
      showTransformModal: false,
      discountTypes: [],
      selectedDiscount: {
        course: {},
        student: {},
        type: {},
        amount: 0
      },
      pagination: {
        rowsPerPage: 30
      },
      jq: $,
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: `${this.$hostUrl}student/${this.$route.params.id}/courses`,
          data: (d) => {
            d.subject_id = selectedSubject
          },
          dataSrc: (json) => {
            this.allCourses = json.data
            return json.data
          }
        },
        buttons: [
          {
            extend: 'print',
            text: 'طباعة',
            titleAttr: 'print'
          },
          {
            extend: 'copy',
            text: 'نسخ',
            titleAttr: 'copy'
          },
          {
            extend: 'csv',
            text: 'إكسل',
            titleAttr: 'CSV'
          }
        ],
        dom:
          "<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-6'p><'col-sm-12 col-md-6'i>>",
        paging: true,
        language: {
          url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Arabic.json',
          processing:
            '<i class="fa fa-spinner fa-spin" style="font-size:40px;color:#7aeb7f"></i>'
        },
        responsive: true,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        serverSide: false,
        fixedHeader: true,
        saveState: true,
        select: {
          style: 'multi'
        }
      },
      fields: {
        name: {
          label: 'اسم الدورة',
          sortable: true,
          searchable: true
        },
        subject: {
          label: 'المادة',
          sortable: true,
          searchable: true,
          render: this.returnSubject
        },
        total_cost: {
          label: 'القيمة',
          sortable: true,
          searchable: true
        },
        actions: {
          isLocal: true,
          label: 'تسجيل',
          defaultContent:
            '&ensp;<a href="javascript:void(0)"; class="pointer" data-action="register"><i class="mdi mdi-square-edit-outline "></i><span class="badge badge-sm bg-gradient-warning">سجل الأن</span></a>'
        }
      }
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}students/${this.$route.params.id}`)
      .then((response) => {
        this.student = response.data.data
        console.log('response.data.data', response.data.data.my_courses)
        this.registeredCourses = response.data.data.my_courses
      })
    const subjects = []
    this.$http.get(`${this.$hostUrl}subjects`).then((response) => {
      $.each(response.data.data, function (key, val) {
        subjects.push({
          name: val.name,
          id: val.id
        })
      })
      this.subjects = subjects
    })
  },
  methods: {
    openModal () {
      this.showModal = true
    },
    openDiscountModal () {
      this.showDiscountModal = true
    },
    returnBranch (data, type, row, meta) {
      return data.name
    },
    returnSubject (data, type, row, meta) {
      return data.name
    },
    filterDate ($event) {
      selectedSubject = this.selectedSubject.id
      const table = this.$refs.table
      table.reload()
    },
    doAlertRegister (data, row, tr) {
      console.log(data.id, this.$route.params.id)
      const formData = new FormData()
      formData.append('student_id', this.$route.params.id)
      formData.append('course_id', data.id)
      this.errors = this.postRequest(
        formData,
        this.$hostUrl + 'course/enroll',
        ''
      ).then((response) => {
        console.log('response', (response))
        if (response.length || typeof response === 'object') {
          if (response.course_id) { swal(response.course_id[0]) }
        }
      })
      const table = this.$refs.table
      // refresh data
      setTimeout(() => {
        table.reload()
      }, 2000)
      this.$http
        .get(`${this.$hostUrl}students/${this.$route.params.id}`)
        .then((response) => {
          this.registeredCourses = response.data.data.my_courses
        })
    },
    async Unroll (id) {
      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد الموافقة ، سيتم إلغاء التسجيل في الدورة  !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .delete(
              `${this.$hostUrl}course/unroll/${id}/${this.$route.params.id}`,
              { _method: 'delete' }
            )
            .then((response) => {
              if (!response.data.error) {
                console.log(response.data)
                swal({ title: response.data.message, buttons: 'تم' })
                $('#unroll').parent().parent().remove()
                setTimeout(() => {
                  // location.reload()
                }, 1000)
              }
            }).catch(function (error) {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    async addDiscount (course) {
      this.showModal = true
      this.selectedDiscount.student = {
        id: this.student.id,
        name: this.student.full_name
      }
      console.log(this.$route.params.id)
      this.selectedDiscount.course = {
        id: course.id,
        number: course.number,
        name: course.name,
        discounts: {}
      }
      console.log(this.selectedDiscount)
      const discountTypes = []
      this.$http.get(`${this.$hostUrl}discount-types`).then((response) => {
        console.log(response.data)
        $.each(response.data.data, function (key, val) {
          discountTypes.push({
            name: val.name,
            name_en: val.name_en,
            id: val.id
          })
        })
        this.discountTypes = discountTypes
      })
    },
    transformCourse (course) {
      this.showTransformModal = true
      this.fromCourse = course
      console.log('this.allCourses', this.allCourses)
      this.allCourses = this.allCourses.filter((item) => {
        return (item.subject_id === course.subject_id)
      })
    },
    completeTransform () {
      this.showTransformModal = false
      this.reloadTable()
    },
    reloadTable () {
      this.$http
        .get(`${this.$hostUrl}students/${this.$route.params.id}`)
        .then((response) => {
          this.student = response.data.data
          this.registeredCourses = response.data.data.my_courses
        })
    },
    async removeDiscount (course) {
      this.showDiscountModal = true
      this.selectedDiscount.student = {
        id: this.student.id,
        name: this.student.full_name
      }
      this.selectedDiscount.course = {
        id: course.id,
        number: course.number,
        name: course.name,
        discounts: course.discounts
      }
      console.log('course', this.selectedDiscount.course)
    },
    async editDiscount (discount, selectedDiscount, e) {
      this.showDiscountModal = false
      const current = this
      const formData = new FormData()
      formData.append('student_id', this.student.id)
      formData.append('course_id', this.selectedDiscount.course.id)
      formData.append('discount_type_id', discount.discount_type_id)
      if (discount.discount_type.name_en === 'course_discount') {
        formData.append('amount', discount.amount)
      }
      formData.append('_method', 'PUT')
      await this.$http.post(this.$hostUrl + 'discounts/' + discount.id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          swal({ title: response.data.message, buttons: 'تم' })
        })
        .catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            console.log('2')
            if (error.response.data.data) {
              current.errors = error.response.data.data
              swal(current.errors.student_id || current.errors.course_id)
            } else {
              swal({ title: error.response.data.message, buttons: 'تم' })
            }
            window.scrollTo(0, 0)
          }
        })
      this.reloadTable()
    },
    async deleteDiscount (discount, selectedDiscount, e) {
      this.showDiscountModal = false
      console.log('deleteDiscount', this.selectedDiscount)
      const current = this
      const formData = new FormData()
      formData.append('_method', 'Delete')
      await this.$http.post(this.$hostUrl + 'discounts/' + discount.id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          swal({ title: response.data.message, buttons: 'تم' })
        })
        .catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            console.log('2')
            if (error.response.data.data) {
              current.errors = error.response.data.data
              swal(current.errors.student_id || current.errors.course_id)
            } else {
              swal({ title: error.response.data.message, buttons: 'تم' })
            }
            window.scrollTo(0, 0)
          }
        })
      this.reloadTable()
    },
    async createDiscount (e) {
      const current = this
      const formData = new FormData()
      formData.append('student_id', this.student.id)
      formData.append('course_id', this.selectedDiscount.course.id)
      formData.append('discount_type_id', this.selectedDiscount.type.id)
      if (this.selectedDiscount.type.name_en === 'course_discount') {
        formData.append('amount', this.selectedDiscount.amount)
      }
      await this.$http.post(this.$hostUrl + 'discounts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          swal({ title: response.data.message, buttons: 'تم' })
        })
        .catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            console.log('2')
            if (error.response.data.data) {
              current.errors = error.response.data.data
              swal(error.response.data.data.amount)
            } else {
              swal({ title: error.response.data.message, buttons: 'تم' })
            }
            window.scrollTo(0, 0)
          }
        })
      this.reloadTable()
    },
    getCourse (id) {
      this.$router.push({ path: '/course-detail', query: { id: id } })
    },
    doReload () {
    }
  }
}
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
::v-deep .modal-content {
  width: 50% ;
}
.third-r{
  margin: 40px 20px;
}
.third-r button{
  margin-top: 33px !important;
}
.paid-back{
 background: #baf2c5a1 !important;
}
.padding{
  padding:10px !important
}
</style>
